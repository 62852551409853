<template>
  <v-data-table
    :headers="headers"
    :items="paginatedItems"
    @input="onInput"
    :server-items-length="total"
    :options.sync="existingOrganizationsOptions"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{
          label && label.length > 0 ? label : "Include / Exclude Specific " + $i18n.translate("Organizations")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog persistent v-model="organizationsToAddDialog" v-if="!disabled" width="auto">
          <template v-slot:activator="{ on: onDialog, attrs: attrsDialog }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                <v-btn
                  class="ml-2"
                  fab
                  small
                  v-bind="{ ...attrsDialog, ...attrsTooltip }"
                  v-on="{ ...onDialog, ...onTooltip }"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span
                >{{ $i18n.translate("New") }} {{ $i18n.translate("Promotion") }}
                {{ $i18n.translate("Organization") }}</span
              >
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ $i18n.translate("Add") }} {{ $i18n.translate("Organizations") }}</span>
              <v-spacer> </v-spacer>
              <v-form @submit.stop.prevent="onOrganizationsToAddFilter">
                <v-text-field
                  class="mr-4"
                  v-model="organizationsToAdd.search"
                  :label="$i18n.translate('Filter') + ' ' + $i18n.translate('Organizations')"
                  flat
                  solo-inverted
                  hide-details
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  @click:clear="onOrganizationsToAddClearFilter"
                ></v-text-field>
              </v-form>
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on: onMenu }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: onTooltip }">
                      <v-btn icon v-on="{ ...onMenu, ...onTooltip }">
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $i18n.translate("Advanced Search") }}</span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-list-item-group color="primary">
                    <v-subheader>{{ $i18n.translate("Advanced Search") }}</v-subheader>
                    <v-list-item selectable>
                      <v-list-item-content>
                        <OrganizationTypesField
                          dense
                          v-model="organizationsToAdd.filterByOrganizationTypes"
                          label="Organization Types"
                          @input="getOrganizationsToAddData"
                        ></OrganizationTypesField>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-tooltip bottom v-if="hasFilters">
                <template v-slot:activator="{ on: onTooltip }">
                  <v-btn icon v-on="{ ...onTooltip }" @click="onOrganizationsToAddClearSearch">
                    <v-icon>mdi-magnify-close</v-icon>
                  </v-btn>
                </template>
                <span>{{ $i18n.translate("Clear Search") }}</span>
              </v-tooltip>
            </v-card-title>

            <v-card-text>
              <v-data-table
                v-model="organizationsToAdd.selected"
                show-select
                :items="organizationsToAdd.items"
                :headers="organizationsToAdd.headers"
                :server-items-length="organizationsToAdd.total"
                :options.sync="organizationsToAddOptions"
                :loading="organizationsToAdd.loading"
              >
                <template v-slot:item.organization="{ item }">
                  <span v-if="item.organization">
                    {{ item.organization.name }}
                  </span>
                </template>
                <template v-slot:item.updatedDate="{ item }">
                  {{ item.updatedDate | formatDateFromNow }}
                </template>
              </v-data-table>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="onOrganizationsToAddClose">{{ $i18n.translate("Cancel") }}</v-btn>
              <v-btn color="primary" @click="onOrganizationsToAddSubmit(true)">Include</v-btn>
              <v-btn color="primary" @click="onOrganizationsToAddSubmit(false)">Exclude</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog persistent v-model="organizationEditDialog" v-if="!disabled" width="500">
          <v-card>
            <v-card-title>
              <span class="headline"
                >{{ $i18n.translate("Edit") }} {{ $i18n.translate("Organization") }} -
                {{ editedItem && editedItem.organization ? editedItem.organization.name : "" }}</span
              >
            </v-card-title>

            <v-card-text>
              <v-form :value="valid" @submit.prevent="onSave" v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-switch
                        :label="'Include in ' + $i18n.translate('Promotion') + '?'"
                        v-model="editedItem.includeInPromotion"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="onClose">{{ $i18n.translate("Cancel") }}</v-btn>
              <v-btn color="primary" @click="onSave" :disabled="!valid">{{ $i18n.translate("Save") }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.includeInPromotion="{ item }">
      <v-icon v-if="item.includeInPromotion" color="green">mdi-check</v-icon>
      <v-icon v-else color="red">mdi-close</v-icon>
    </template>
    <template v-slot:item.organization.organization="{ item }">
      <span v-if="item.organization.organization">
        {{ item.organization.organization.name }}
      </span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon class="mr-2" v-if="!disabled" @click="onEditItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon v-if="!disabled" @click="onDeleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import ApiService from "../../services/api.service";
import OrganizationTypesField from "../fields/OrganizationTypesField";

export default {
  name: "PromotionOrganizationsTable",
  props: {
    promotionId: Number,
    value: Object,
    disabled: Boolean,
    label: {
      type: String,
      required: false
    },
    isCopy: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OrganizationTypesField
  },
  data: () => ({
    valid: false,
    loaded: false,
    organizationEditDialog: false,
    organizationsToAddDialog: false,
    rules: {
      payoutType: [v => !!v || "Payout Type is required"],
      payoutAmount: [
        v => !!v || "Payout Amount is required",
        v => (!!v && v < 49999) || "Payout Amount must be lower than 49999"
      ]
    },
    items: {
      existing: [],
      deleted: []
    },
    existingOrganizationsOptions: {
      page: 1,
      itemsPerPage: 10
    },
    editedIndex: -1,
    editedItem: {},
    editedPromotionOrganizationPayouts: [],
    defaultItem: {},

    organizationsToAdd: {
      search: "",
      filterByOrganizationTypes: [],
      loading: false,
      selected: [],
      items: [],
      nameFilter: "",
      headers: [
        { text: "Key", value: "organizationKey" },
        { text: "Name", value: "name" },
        { text: "DBA", value: "DBA" },
        {
          value: "parentOrganization.name",
          text: "Parent Organization"
        },
        {
          value: "organizationType.name",
          text: "Organization Type",
          align: "end"
        },
        {
          value: "updatedDate",
          text: "Last Updated",
          sortable: true
        }
      ]
    },
    total: 0,
    organizationsToAddOptions: {
      itemsPerPage: 10
    }
  }),
  watch: {
    organizationEditDialog(val) {
      val || this.onClose();
    },
    organizationsToAddOptions: {
      handler() {
        this.getOrganizationsToAddData();
      },
      deep: true
    },
    promotionId: {
      immediate: true,
      handler() {
        this.initialize();
      }
    },
    "$route.params.id": {
      immediate: true,
      deep: true,
      handler() {
        this.initialize();
      }
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      if (this.promotionId > 0) {
        let pageNumber = 1;
        let totalPages = 10;
        let pageSize = 2;
        let { data } = await ApiService.post(
          "/api/promotionOrganizations/promotion/" +
            this.promotionId +
            "/search?size=" +
            pageSize +
            "&page=" +
            (pageNumber - 1),
          {}
        );
        totalPages = data.totalPages;
        let { data: response } = await ApiService.post(
          "/api/promotionOrganizations/promotion/" +
            this.promotionId +
            "/search?size=" +
            pageSize * totalPages +
            "&page=" +
            (pageNumber - 1),
          {}
        );
        this.items.existing = response.content;
        this.total = response.totalElements;
        this.existingOrganizationsOptions = {
          page: 1,
          itemsPerPage: 10
        };

        if (this.isCopy) {
          this.items.existing.forEach(elem => (elem.id = undefined));
        }
        this.items.deleted = [];
        this.$emit("input", this.items);
        this.loaded = true;
      }
    },

    onInput() {
      this.$emit("input", this.items);
    },

    onEditItem(item) {
      this.editedIndex = this.items.existing.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.organizationEditDialog = true;
    },

    onDeleteItem(item) {
      const index = this.items.existing.indexOf(item);
      let deletedItems = this.items.existing.splice(index, 1);
      deletedItems = deletedItems.reduce((acc, cur) => {
        if (cur.id) {
          acc.push(cur);
        }
        return acc;
      }, []);
      this.items.deleted = this.items.deleted.concat(deletedItems);
      this.total--;
      this.$emit("input", this.items);
      if (this.loaded) {
        this.$emit("change");
      }
    },

    onClose() {
      this.organizationEditDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    onOrganizationsToAddFilter() {
      this.organizationsToAddOptions.page = 0;
      this.getOrganizationsToAddData();
    },

    onOrganizationsToAddClearFilter() {
      this.organizationsToAdd.search = "";
      this.getOrganizationsToAddData();
    },
    onOrganizationsToAddClearSearch() {
      this.organizationsToAdd.search = "";
      this.organizationsToAdd.filterByOrganizationTypes = [];
      this.getOrganizationsToAddData();
    },

    async onOrganizationsToAddSubmit(include) {
      let promotionOrganizations = this.items.existing.slice();

      this.organizationsToAdd.selected.some(organization => {
        let foundIndex = promotionOrganizations.findIndex(needle => needle.organization.id == organization.id);

        if (foundIndex == -1) {
          promotionOrganizations = promotionOrganizations.concat([
            { dirty: true, includeInPromotion: include, organization: organization }
          ]);

          this.total++;
        } else {
          promotionOrganizations[foundIndex].includeInPromotion = include;
          promotionOrganizations[foundIndex].dirty = true;
        }
      });
      this.items.existing = promotionOrganizations;

      this.onOrganizationsToAddClose();

      this.$emit("input", this.items);
      if (this.loaded) {
        this.$emit("change");
      }
    },

    onOrganizationsToAddClose() {
      this.organizationsToAddDialog = false;
      this.onOrganizationsToAddClearFilter();
      this.onOrganizationsToAddClearSearch();
      this.organizationsToAdd.selected = [];
      this.onOrganizationsToAddClearFilter();
    },

    onSave() {
      this.editedItem.dirty = true;
      this.editedItem.promotionOrganizationPayouts = this.editedPromotionOrganizationPayouts;
      if (this.editedIndex > -1) {
        Object.assign(this.items.existing[this.editedIndex], this.editedItem);
      } else {
        this.items.existing.push(this.editedItem);
      }
      this.$emit("input", this.items);
      if (this.loaded) {
        this.$emit("change");
      }
      this.onClose();
    },

    getOrganizationsToAddData() {
      this.organizationsToAdd.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.organizationsToAddOptions;

      let filters = {};
      if (this.organizationsToAdd.search && this.organizationsToAdd.search.length > 0) {
        filters.keyword = this.organizationsToAdd.search;
      }

      if (
        this.organizationsToAdd.filterByOrganizationTypes &&
        this.organizationsToAdd.filterByOrganizationTypes.length > 0
      ) {
        filters.organizationTypes = this.organizationsToAdd.filterByOrganizationTypes.map(organizationType => {
          return {
            id: organizationType.id
          };
        });
      }
      ApiService.post(
        "/api/organizations/search?size=" +
          itemsPerPage +
          "&page=" +
          (page - 1) +
          (sortBy && sortBy.length > 0
            ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
            : ""),
        filters
      )
        .then(({ data }) => {
          this.organizationsToAdd.loading = false;
          this.organizationsToAdd.items = data.content;
          this.organizationsToAdd.total = data.totalElements;
        })
        .catch(() => {
          this.organizationsToAdd.loading = false;
        });
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Promotion Organization" : "Edit Promotion Organization Field";
    },
    hasFilters() {
      let check =
        (this.search && this.search.length > 0) ||
        (this.organizationsToAdd.filterByOrganizationTypes &&
          this.organizationsToAdd.filterByOrganizationTypes.length > 0);
      return check;
    },
    headers() {
      return [
        {
          text: "Include / Exclude",
          value: "includeInPromotion",
          align: "center"
        },
        { text: this.$i18n.translate("Organization Key"), value: "organization.organizationKey" },
        { text: this.$i18n.translate("Organization Name"), value: "organization.name" },
        { text: this.$i18n.translate("Dba"), value: "organization.DBA" },
        {
          value: "organization.parentOrganization.name",
          text: this.$i18n.translate("Parent Organization")
        },
        {
          value: "organization.organizationType.name",
          text: this.$i18n.translate("Organization Type"),
          align: "end"
        },

        { text: "Actions", value: "actions", sortable: false }
      ];
    },
    paginatedItems() {
      const start = (this.existingOrganizationsOptions.page - 1) * this.existingOrganizationsOptions.itemsPerPage;
      const end = start + this.existingOrganizationsOptions.itemsPerPage;
      return this.items.existing.slice(start, end);
    }
  }
};
</script>
