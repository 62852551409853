<template>
  <v-container>
    <v-row justify="end">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab small class="mr-2" v-on="on" @click="isEditing = true">
            <v-icon v-if="isEditing">mdi-close</v-icon>
            <v-icon v-else>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span v-if="isEditing">{{ $i18n.translate("Close") }}</span>
        <span v-else
          >{{ $i18n.translate("Edit") }} {{ $i18n.translate("Claim") }} {{ $i18n.translate("Product") }}</span
        >
      </v-tooltip>
      <v-tooltip bottom v-if="isEditing">
        <template v-slot:activator="{ on }">
          <v-btn fab small class="mr-2" v-on="on" @click="addNewRule()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Add") }} {{ $i18n.translate("Claim") }} {{ $i18n.translate("Product") }}</span>
      </v-tooltip>
      <v-tooltip bottom v-if="isEditing">
        <template v-slot:activator="{ on }">
          <v-btn fab small class="mr-2" v-on="on" @click="saveRuleGroup()">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </template>
        <span>{{ $i18n.translate("Save") }}</span>
      </v-tooltip>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="visibleItems">
          <template v-slot:item.ruleCondition="{ item }"
            ><v-text-field
              :disabled="!isEditing"
              v-if="item && item.ruleCondition"
              v-model="item.ruleCondition.spelExpression"
              label="Condition"
            ></v-text-field>
          </template>
          <template v-slot:item.expression="{ item }">
            <v-text-field
              :disabled="!isEditing"
              v-if="item && item.ruleAction && item.ruleAction[0] && item.ruleAction[0].context"
              v-model="item.ruleAction[0].context.expression"
              label="Expression"
            ></v-text-field
          ></template>
          <template v-slot:item.awardVehicle="{ item }">
            <AwardVehicleField
              :disabled="!isEditing"
              v-if="item && item.ruleAction && item.ruleAction[0] && item.ruleAction[0].context"
              v-model="item.ruleAction[0].context.awardVehicle"
              :valueId="item.ruleAction[0].context.awardVehicleId"
            >
            </AwardVehicleField>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon v-if="isEditing" @click="$set(item, 'toBeDeleted', true)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApiService from "../../services/api.service";
import AwardVehicleField from "../fields/AwardVehicleField";

export default {
  name: "RuleGroupTable",
  props: {
    promotion: Object
  },
  components: {
    AwardVehicleField
  },
  data: () => ({
    headers: [
      { value: "ruleCondition", text: "Condition" },
      { value: "expression", text: "Action Expression" },
      { value: "awardVehicle", text: "Award Vehicle" },
      { value: "actions", text: "Actions" }
    ],
    items: [],

    isEditing: false,
    ruleGroupId: undefined
  }),
  created() {},
  watch: {
    promotion: {
      immediate: true,
      handler(v) {
        if (v) {
          this.loadRuleGroup();
        }
      }
    }
  },
  computed: {
    visibleItems() {
      if (this.items) {
        return this.items.filter(i => {
          return !i.toBeDeleted;
        });
      } else {
        return [];
      }
    }
  },
  methods: {
    addNewRule() {
      let newItem = {
        ruleCondition: {},
        ruleAction: [
          {
            actionKey: "generateMoneyPayoutAction",
            context: { generatePayoutActionType: "PAYOUT", expression: "" },
            awardVehicle: null,
            awardVehicleId: null
          }
        ]
      };
      this.items.push(newItem);
    },
    saveRuleGroup() {
      ApiService.patch("/api/promotions/" + this.promotion.id, {
        payoutGenerationRuleGroup: null
      }).then(() => {
        if (this.ruleGroupId) {
          ApiService.delete("/api/ruleEngine/deleteRuleGroup/" + this.ruleGroupId).catch(error => {
            console.log(error);
          });
        }

        let itemsCopy = JSON.parse(JSON.stringify(this.items));
        itemsCopy = itemsCopy.filter(item => !item.toBeDeleted);
        itemsCopy.forEach(item => {
          if (item.ruleAction[0].context) {
            item.ruleAction[0].actionKey = "generateMoneyPayoutAction";
            if (item.ruleAction[0].context.awardVehicle) {
              item.ruleAction[0].context.awardVehicleId = item.ruleAction[0].context.awardVehicle.id;
            }
            delete item.ruleAction[0].context.awardVehicle;
            item.ruleAction[0].context.generatePayoutActionType = "PAYOUT";
            item.ruleAction[0].stringContext = JSON.stringify(item.ruleAction[0].context);
          }
        });
        ApiService.post("/api/ruleEngine/saveRuleGroup", { rules: itemsCopy }).then(({ data }) => {
          this.ruleGroupId = data.id;
          ApiService.patch("/api/promotions/" + this.promotion.id, {
            payoutGenerationRuleGroup: ApiService.getSelfUrl("ruleGroups", { id: this.ruleGroupId })
          }).then(() => {
            this.isEditing = false;
          });
        });
      });
    },

    loadRuleGroup() {
      if (this.promotion && this.promotion.id) {
        return ApiService.get("/api/ruleEngine/getPayoutRuleGroupByPromotion/" + this.promotion.id)
          .then(response => {
            let ruleGroupDTO = response.data;
            ruleGroupDTO.rules.forEach(rule => {
              if (rule.ruleAction && rule.ruleAction.length > 0) {
                rule.ruleAction.forEach(ruleAction => {
                  if (ruleAction.stringContext) {
                    try {
                      ruleAction.context = JSON.parse(ruleAction.stringContext);
                    } catch (error) {
                      ruleAction.context = {};
                    }
                  } else {
                    ruleAction.context = {};
                  }
                });
              }
            });
            this.items = ruleGroupDTO.rules;
            this.ruleGroupId = ruleGroupDTO.id;
          })
          .catch(error => {
            console.error("No RuleGroup", error);
          });
      } else {
        return Promise.resolve();
      }
    }
  }
};
</script>
