<template>
  <v-card>
    <v-tabs v-model="tab">
      <v-tab>
        <span>{{ $i18n.translate("Details") }}</span>
      </v-tab>
      <v-tab>
        <span>{{ $i18n.translate("Requesters") }}</span>
      </v-tab>
      <v-tab>
        <span>{{ $i18n.translate("Approvers") }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field
                name="linkToInvitaton"
                label="Promotion Invitation Link"
                id="linkToInvitation"
                v-model="configValues.linkToInvitation"
                :rules="rules.linkToInvitation"
                :disabled="disabled"
                :hint="selectedProgram.programUrl ? selectedProgram.programUrl : ''"
                class="required"
              ></v-text-field>

              <EmailTemplateField
                name="promotionInvitationEmailTemplate"
                label="Email Template"
                v-model="configValues.emailTemplate"
                :disabled="disabled"
                :rules="rules.emailTemplate"
                templateScope="program"
                :hasSendGridTemplate="true"
                class="required"
                @input="onInput"
              />

              <EmailTemplateField
                name="approverEmailTemplate"
                label="Approver Email Template"
                v-model="configValues.approverEmailTemplate"
                :disabled="disabled"
                templateScope="program"
                :hasSendGridTemplate="true"
                @input="onInput"
              />

              <EmailTemplateField
                name="rejectedEmailTemplate"
                label="Rejected Email Template"
                v-model="configValues.rejectedEmailTemplate"
                :disabled="disabled"
                templateScope="program"
                :hasSendGridTemplate="true"
                @input="onInput"
              />

              <EmailTemplateField
                name="redeemedEmailTemplate"
                label="Redeemed Email Template"
                v-model="configValues.redeemedEmailTemplate"
                :disabled="disabled"
                templateScope="program"
                :hasSendGridTemplate="true"
                @input="onInput"
              />

              <v-text-field
                name="approvalsNeeded"
                label="Approvals Needed"
                id="approvalsNeeded"
                v-model="configValues.approvalsNeeded"
                :rules="rules.approvalsNeeded"
                :disabled="disabled"
              ></v-text-field>

              <v-checkbox
                id="redeemable"
                v-model="configValues.promotionInvitationRedeemable"
                label="Redeemable?"
                :disabled="disabled"
              ></v-checkbox>

              <ParticipantTypesField
                v-model="configValues.participantTypesManagement"
                label="Participant Types Management"
                :disabled="disabled"
                @input="onInput"
              >
              </ParticipantTypesField>

              <ParticipantTypeField
                showKey
                v-model="configValues.defaultParticipantType"
                label="Default Participant Type during Enrollment"
                :disabled="disabled"
                @input="onInput"
              >
              </ParticipantTypeField>

              <ParticipantStatusField
                v-model="configValues.defaultParticipantStatus"
                label="Default Participant Status during Enrollment"
                :disabled="disabled"
                @input="onInput"
              >
              </ParticipantStatusField>
            </v-col>
            <v-col cols="6">
              <h2>Information</h2>
              <ul>
                <li>
                  <b>Promotion Invitation Link</b>: Url to access the promotion. The following will be appended to this
                  Url: "{promotionId}?invitationKey={invitationKey}"
                </li>
                <li>
                  <b>Invitation Email Template</b>: When an invitation is approved, this email template will be
                  submitted.
                </li>
                <li>
                  <b>Approver Email Template</b>: Email sent to the <b>Approver</b> with all Promotion Invitation
                  information.
                </li>
                <li>
                  <b>Rejected Email Template</b>: Email sent back to the <b>Requester</b> if the invitation request was
                  rejected.
                </li>
                <li><b>Approvals Needed</b>: Number of approvals needed before the invitation is sent.</li>

                <li>
                  <b>{{ $i18n.translate("Participant Types") }} Management</b>: Variable for controlling the "force"
                  submit, edition, approval, rejection and cancel of the invitation.
                </li>

                <li>
                  <b>Default {{ $i18n.translate("Participant Type") }} during Enrollment</b>: When an invitation is
                  submitted and a
                  {{ $i18n.translate("Participant") }}
                  is being enrolled, this is the default {{ $i18n.translate("Participant") }} type assigned.
                </li>
                <li>
                  <b>Default Participant Status during Enrollment</b>: When an invitation is submitted and a
                  {{ $i18n.translate("Participant") }} is being enrolled, this is the default
                  {{ $i18n.translate("Participant") }} status assigned
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container>
          <v-row>
            <v-col cols="12">
              <ParticipantsTable
                :label="$i18n.translate('Participants') + ' that can create or submit a Promotion Invitation'"
                :disabled="disabled"
                v-model="configValues.participantRequesters"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <ParticipantsTable
              :label="$i18n.translate('Participants') + ' that can approve or reject a Promotion Invitation'"
              :disabled="disabled"
              v-model="configValues.participantApprovers"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import ParticipantsTable from "../tables/ParticipantsTable";
import EmailTemplateField from "@/gapp-components/components/fields/EmailTemplateField";
import ParticipantTypeField from "@/gapp-components/components/fields/ParticipantTypeField";
import ParticipantTypesField from "@/gapp-components/components/fields/ParticipantTypesField";
import ParticipantStatusField from "@/gapp-components/components/fields/ParticipantStatusField";
import { mapGetters } from "vuex";

export default {
  name: "PromotionInvitationConfigForm",
  props: {
    value: Object,
    disabled: Boolean
  },

  components: {
    ParticipantsTable,
    EmailTemplateField,
    ParticipantTypeField,
    ParticipantTypesField,
    ParticipantStatusField
  },

  data: () => ({
    tab: null,
    configValues: {},
    rules: {
      approvalsNeeded: [v => !(v && v.length > 0) || v >= 0 || "Approvals Needed must be zero or a positive number"],
      emailTemplate: [v => !!v || "Email Template is required."],
      linkToInvitation: [v => !!v || "Promotion Invitation Link is required."]
    }
  }),
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters(["selectedProgram"])
  },
  methods: {
    initialize() {
      this.configValues = this.value;
    },
    onInput() {
      this.$emit("input", this.configValues);
    }
  }
};
</script>
