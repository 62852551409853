var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-card',[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"6"}},[(_vm.showTitle)?_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Promotion Limits ")]),_c('v-spacer'),(!_vm.editing && _vm.showEditButton)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){_vm.localIsEditing = true}}},[(!_vm.isEditing)?_c('v-icon',[_vm._v("mdi-pencil")]):_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),(_vm.isEditing && _vm.showSaveButton)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":_vm.onSubmit}},[_c('v-icon',[_vm._v("mdi-content-save")])],1):_vm._e(),(_vm.isEditing && _vm.showAddButton)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.addItem(_vm.tree)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e(),(_vm.isEditing && _vm.showAddButton)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":_vm.addItemGroup}},[_c('v-icon',[_vm._v("mdi-plus-box-multiple")])],1):_vm._e()],1):_vm._e()],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',[_c('v-btn-toggle',{attrs:{"divided":"","dense":""},model:{value:(_vm.tree.nodeType),callback:function ($$v) {_vm.$set(_vm.tree, "nodeType", $$v)},expression:"tree.nodeType"}},[_c('v-btn',{attrs:{"disabled":!_vm.isEditing,"value":"AND"}},[_vm._v("AND")]),_c('v-btn',{attrs:{"disabled":!_vm.isEditing,"value":"OR"}},[_vm._v("OR")])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tree && _vm.tree.children ? _vm.tree.children : [],"disable-pagination":"","hide-default-footer":"","loading":_vm.isBusy,"single-expand":true,"expanded":_vm.expanded},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(item.nodeType && !isExpanded)?_c('v-btn',{on:{"click":function($event){return expand(true)}}},[_vm._v("Expand")]):_vm._e(),(item.nodeType && isExpanded)?_c('v-btn',{on:{"click":function($event){return expand(false)}}},[_vm._v("close")]):_vm._e()]}},{key:"item.expand",fn:function(){return [_vm._v(" Condition Group ")]},proxy:true},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('v-container',{staticStyle:{"width":"100%"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"10"}},[_c('v-btn-toggle',{attrs:{"divided":"","dense":""},model:{value:(item.nodeType),callback:function ($$v) {_vm.$set(item, "nodeType", $$v)},expression:"item.nodeType"}},[_c('v-btn',{attrs:{"value":"AND"}},[_vm._v("AND")]),_c('v-btn',{attrs:{"value":"OR"}},[_vm._v("OR")])],1)],1),_c('v-col',{attrs:{"cols":"1"}},[(_vm.isEditing && _vm.showAddButton)?_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.addItem(item)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"1"}}),_c('v-col',{attrs:{"cols":"11"}},[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"headers":_vm.subitemHeaders,"items":item.children,"disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.customField4Value",fn:function(ref){
var item = ref.item;
return [(_vm.isEditing)?_c('v-autocomplete',{attrs:{"type":"text","rules":[function (v) { return !!v || 'Required'; }],"items":_vm.mpgs,"item-text":"description","item-value":"value"},on:{"change":function($event){item.dirty = true}},model:{value:(item.customField4Value),callback:function ($$v) {_vm.$set(item, "customField4Value", $$v)},expression:"item.customField4Value"}}):[_vm._v(_vm._s(item.customField4Value))]]}},{key:"item.maxQuantity",fn:function(ref){
var item = ref.item;
return [(_vm.isEditing)?_c('v-text-field',{attrs:{"type":"number","rules":[function (v) { return !!v || 'Required'; }]},on:{"change":function($event){item.dirty = true}},model:{value:(item.maxQuantity),callback:function ($$v) {_vm.$set(item, "maxQuantity", $$v)},expression:"item.maxQuantity"}}):[_vm._v(_vm._s(item.maxQuantity))]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isEditing)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_vm._e()]}}],null,true)})],1)],1)],1)]}},{key:"item.customField4Value",fn:function(ref){
var item = ref.item;
return [(_vm.isEditing && !item.nodeType)?_c('v-autocomplete',{attrs:{"type":"text","rules":[function (v) { return !!v || 'Required'; }],"items":_vm.mpgs,"item-text":"description","item-value":"value"},on:{"change":function($event){item.dirty = true}},model:{value:(item.customField4Value),callback:function ($$v) {_vm.$set(item, "customField4Value", $$v)},expression:"item.customField4Value"}}):(!item.nodeType)?[_vm._v(_vm._s(item.customField4Value))]:(item.nodeType)?[_c('i',[_vm._v("CONDITION GROUP")])]:_vm._e()]}},{key:"item.maxQuantity",fn:function(ref){
var item = ref.item;
return [(_vm.isEditing && !item.nodeType)?_c('v-text-field',{attrs:{"type":"number","rules":[function (v) { return !!v || 'Required'; }]},on:{"change":function($event){item.dirty = true}},model:{value:(item.maxQuantity),callback:function ($$v) {_vm.$set(item, "maxQuantity", $$v)},expression:"item.maxQuantity"}}):(!item.nodeType)?[_vm._v(_vm._s(item.maxQuantity))]:_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isEditing)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1):_vm._e()]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }