<template>
  <v-select
    :loading="loading"
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="emailTemplates"
    item-value="id"
    :item-text="item => `${item.emailTemplateKey} - ${item.subject}`"
    clearable
    return-object
  >
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "EmailTemplateField",
  props: {
    value: [Object, String],
    templateScope: {
      type: String,
      default: "program"
    },
    hasSendGridTemplate: Boolean
  },
  data: () => ({
    loading: true,
    emailTemplates: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    fetchData() {
      this.loading = true;

      let filters = {};
      filters.isEffective = "All";
      if (this.templateScope == "programGroup") {
        filters.byProgramGroup = true;
      } else if (this.templateScope == "program") {
        filters.byProgramGroup = false;
      }

      if (this.hasSendGridTemplate == true) {
        filters.hasSendGridTemplate = true;
      } else if (this.hasSendGridTemplate == false) {
        filters.hasSendGridTemplate = false;
      }

      ApiService.post("/api/emailTemplates/search?size=200", filters)
        .then(({ data }) => {
          this.emailTemplates = data.content;
          if (data.totalElements > this.options.itemsPerPage) {
            console.warn("The Email Template Field does not show all the options.");
          }
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
