<template>
  <div>
    <v-container>
      <v-card>
        <v-row class="justify-center">
          <v-col cols="6">
            <v-toolbar flat color="white" v-if="showTitle">
              <v-toolbar-title>Promotion Limits </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn fab small @click="localIsEditing = true" v-if="!editing && showEditButton">
                <v-icon v-if="!isEditing">mdi-pencil</v-icon>
                <v-icon v-else>mdi-close</v-icon>
              </v-btn>

              <v-btn v-if="isEditing && showSaveButton" fab small @click="onSubmit"
                ><v-icon>mdi-content-save</v-icon></v-btn
              >
              <v-btn v-if="isEditing && showAddButton" fab small @click="addItem(tree)"
                ><v-icon>mdi-plus</v-icon></v-btn
              >
              <v-btn v-if="isEditing && showAddButton" fab small @click="addItemGroup"
                ><v-icon>mdi-plus-box-multiple</v-icon></v-btn
              >
            </v-toolbar>
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="6">
            <v-card>
              <v-btn-toggle v-model="tree.nodeType" divided dense>
                <v-btn :disabled="!isEditing" value="AND">AND</v-btn>
                <v-btn :disabled="!isEditing" value="OR">OR</v-btn>
              </v-btn-toggle>

              <v-data-table
                :headers="headers"
                :items="tree && tree.children ? tree.children : []"
                disable-pagination
                hide-default-footer
                :loading="isBusy"
                :single-expand="true"
                :expanded.sync="expanded"
              >
                <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
                  <v-btn @click="expand(true)" v-if="item.nodeType && !isExpanded">Expand</v-btn>
                  <v-btn @click="expand(false)" v-if="item.nodeType && isExpanded">close</v-btn>
                </template>

                <template v-slot:item.expand="">
                  Condition Group
                </template>
                <template v-slot:expanded-item="{ item }">
                  <v-container style="width:100%">
                    <v-row>
                      <v-col cols="1"> </v-col>
                      <v-col cols="10">
                        <v-btn-toggle v-model="item.nodeType" divided dense>
                          <v-btn value="AND">AND</v-btn>
                          <v-btn value="OR">OR</v-btn>
                        </v-btn-toggle>
                      </v-col>
                      <v-col cols="1">
                        <v-btn v-if="isEditing && showAddButton" fab small @click="addItem(item)"
                          ><v-icon>mdi-plus</v-icon></v-btn
                        >
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="1"> </v-col>
                      <v-col cols="11">
                        <v-data-table
                          :headers="subitemHeaders"
                          :items="item.children"
                          disable-pagination
                          hide-default-footer
                          style="width: 100%"
                        >
                          <template v-slot:item.customField4Value="{ item }">
                            <v-autocomplete
                              v-if="isEditing"
                              @change="item.dirty = true"
                              v-model="item.customField4Value"
                              type="text"
                              :rules="[v => !!v || 'Required']"
                              :items="mpgs"
                              item-text="description"
                              item-value="value"
                            ></v-autocomplete>
                            <template v-else>{{ item.customField4Value }}</template>
                          </template>

                          <template v-slot:item.maxQuantity="{ item }">
                            <v-text-field
                              v-if="isEditing"
                              @change="item.dirty = true"
                              v-model="item.maxQuantity"
                              type="number"
                              :rules="[v => !!v || 'Required']"
                            ></v-text-field>
                            <template v-else>{{ item.maxQuantity }}</template>
                          </template>

                          <template v-slot:item.actions="{ item }">
                            <v-btn v-if="isEditing" icon @click="deleteItem(item)">
                              <v-icon>mdi-trash-can-outline</v-icon>
                            </v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>

                <template v-slot:item.customField4Value="{ item }">
                  <v-autocomplete
                    v-if="isEditing && !item.nodeType"
                    @change="item.dirty = true"
                    v-model="item.customField4Value"
                    type="text"
                    :rules="[v => !!v || 'Required']"
                    :items="mpgs"
                    item-text="description"
                    item-value="value"
                  ></v-autocomplete>
                  <template v-else-if="!item.nodeType">{{ item.customField4Value }}</template>
                  <template v-else-if="item.nodeType"><i>CONDITION GROUP</i></template>
                </template>

                <template v-slot:item.maxQuantity="{ item }">
                  <v-text-field
                    v-if="isEditing && !item.nodeType"
                    @change="item.dirty = true"
                    v-model="item.maxQuantity"
                    type="number"
                    :rules="[v => !!v || 'Required']"
                  ></v-text-field>
                  <template v-else-if="!item.nodeType">{{ item.maxQuantity }}</template>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-btn v-if="isEditing" icon @click="deleteItem(item)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "PromotionLimit",
  data() {
    return {
      localIsEditing: false,
      data: [],
      deletedItems: [],
      items: [],
      filteredItems: [],
      isBusy: false,
      mpgs: [],
      tree: []
    };
  },
  props: {
    promotion: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean,
      required: false
    },
    showEditButton: {
      type: Boolean,
      default: true
    },
    showSaveButton: {
      type: Boolean,
      default: true
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.fetchData();
    this.updateTree();
  },
  methods: {
    async generateTree(promotionId) {
      if (promotionId) {
        let root = await this.$api.get("/api/promotionLimitNodes/byPromotionId/" + promotionId);
        if (root && root.data && root.data != "") {
          root = root.data;
          root.children = await this.getChildren(root);
          root.children.sort((a, b) => {
            if (a.nodeType === undefined || a.nodeType === null) {
              return -1;
            }
            if (b.nodeType === undefined || b.nodeType === null) {
              return 1;
            }
            return a.createdDate - b.createdDate;
          });
          this.tree = root;
        } else {
          this.tree = { promotion: this.promotion, nodeType: "AND", children: [] };
        }
      } else {
        return Promise.resolve();
      }
    },

    async getChildren(node) {
      let children = await this.$api.get("/api/promotionLimitNodes/byNode/" + node.id);
      children = children.data;
      for (let child of children) {
        child.children = await this.getChildren(child);
        if (child.children && child.children.length > 0) {
          this.expanded.push(child);
        }
      }
      return children;
    },

    updateTree() {
      if (!this.tree || !this.tree.children) return;
      for (let index in this.tree.children) {
        let currentChild = this.tree.children[index];
        for (let innerIndex in currentChild.children) {
          let currentInnerChild = currentChild.children[innerIndex];

          if (currentInnerChild.deleted) {
            delete this.tree.children[index].children[innerIndex];
          }
        }
        if (currentChild.deleted) {
          delete this.tree.children[index];
        }
      }
    },

    deleteItem(item) {
      this.deletedItems.push(item);
      this.$set(item, "deleted", true);
      this.updateTree();
    },

    async fetchData() {
      this.isBusy = true;
      this.deletedItems = [];
      await this.generateTree(this.promotion.id);
      this.mpgs = await this.$api.get("/api/promotionLimitNodes/customField4ValuesForProducts");
      this.mpgs = this.mpgs.data.map(elem => {
        return { description: elem[1], value: elem[0] };
      });
      this.items = this.tree;
      this.updateTree();
      this.isBusy = false;
    },

    getDto(elem) {
      if (!elem) return null;
      return {
        customField4Name: elem.customField4Name,
        customField4Value: elem.customField4Value,
        maxQuantity: elem.maxQuantity,
        nodeType: elem.noteType ? elem.nodeType.name : undefined
      };
    },
    async onSubmit() {
      this.isBusy = true;
      if (!this.tree || !this.tree.children) return;

      for (let deletedItem of this.deletedItems) {
        if (deletedItem.id) {
          await this.$api.delete("/api/promotionLimitNodes/" + deletedItem.id);
        }
      }

      this.deletedItems = [];
      let rootElement = null;
      if (this.tree) {
        let postForm = this.getDto(this.tree);
        postForm.promotion = this.$api.getSelfUrl("promotion", this.promotion);
        postForm.nodeType = this.tree.nodeType;
        if (this.tree.id) {
          postForm.id = this.tree.id;
          await this.$api.patch("/api/promotionLimitNodes/" + this.tree.id, postForm).then(({ data }) => {
            rootElement = data;
          });
        } else if (this.tree.children && this.tree.children.length > 0) {
          await this.$api.post("/api/promotionLimitNodes/", postForm).then(({ data }) => {
            rootElement = data;
          });
        }
        for (let topLevelChild of this.tree.children) {
          if (!topLevelChild) continue;
          let postForm = this.getDto(topLevelChild);
          postForm.parentPromotionLimitNode = this.$api.getSelfUrl("promotionLimitNode", rootElement);
          postForm.nodeType = topLevelChild.nodeType ? topLevelChild.nodeType : undefined;
          let topLevelElement = null;
          if (topLevelChild.id) {
            postForm.id = topLevelChild.id;
            await this.$api.patch("/api/promotionLimitNodes/" + topLevelChild.id, postForm).then(({ data }) => {
              topLevelElement = data;
            });
          } else {
            await this.$api.post("/api/promotionLimitNodes/", postForm).then(({ data }) => {
              topLevelElement = data;
            });
          }
          if (topLevelChild.children) {
            for (let lastLevelChild of topLevelChild.children) {
              if (!lastLevelChild) continue;
              let postForm2 = this.getDto(lastLevelChild);
              postForm2.parentPromotionLimitNode = this.$api.getSelfUrl("promotionLimitNode", topLevelElement);
              if (lastLevelChild.id) {
                await this.$api.patch("/api/promotionLimitNodes/" + lastLevelChild.id, postForm2);
              } else {
                await this.$api.post("/api/promotionLimitNodes/", postForm2);
              }
            }
          }
        }
      }
      this.localIsEditing = false;
      this.fetchData();
      this.isBusy = false;
    },
    getPostItem(item) {
      let postItem = {
        id: item.id,
        promotion: this.$api.getSelfUrl("promotion", this.promotion),
        customField4Name: "MPG",
        customField4Value: item.customField4Value,
        maxQuantity: item.maxQuantity
      };
      return postItem;
    },
    addItem(item) {
      let newItem = {
        customField4Value: "",
        maxQuantity: 0,
        deleted: false
      };
      item.children.push(newItem);
    },
    addItemGroup() {
      let newItemChildren = {
        customField4Value: "",
        maxQuantity: 0,
        deleted: false
      };
      let newItem = {
        customField4Value: "",
        maxQuantity: 0,
        deleted: false,
        nodeType: "AND",
        children: [newItemChildren]
      };
      this.tree.children.push(newItem);
      this.expanded.push(newItem);
    },
    deleteElements(tree) {
      if (!tree || tree.deleted) {
        return null;
      }
      let children = [];
      for (let i = 0; i < tree.children.length; i++) {
        let child = this.deleteElements(tree.children[i]);
        if (child) {
          children.push(child);
        }
      }
      tree.children = children;
      return tree;
    }
  },
  computed: {
    expanded() {
      if (this.tree && this.tree.children && Array.isArray(this.tree.children)) {
        return this.tree.children.slice().filter(child => child.children && child.children.length > 0);
      }
      return [];
    },
    headers() {
      let baseHeaders = [
        { text: "MPG", value: "customField4Value" },
        { text: "Limit", value: "maxQuantity" }
      ];
      if (this.isEditing) {
        baseHeaders.push({ text: "Actions", value: "actions", align: "right" });
      }
      return baseHeaders;
    },
    subitemHeaders() {
      let baseHeaders = [
        { text: "MPG", value: "customField4Value" },
        { text: "Limit", value: "maxQuantity" }
      ];
      if (this.isEditing) {
        baseHeaders.push({ text: "Actions", value: "actions", align: "right" });
      }
      return baseHeaders;
    },
    isEditing() {
      if (!this.editing) {
        return this.localIsEditing;
      } else {
        return this.editing;
      }
    },
    finalData() {
      return this.data.filter(elem => !elem.deleted);
    }
  }
};
</script>
