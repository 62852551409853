<template>
  <v-autocomplete
    v-model="selectedProduct"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="products"
    no-filter
    item-text="productNumber"
    item-value="id"
    clearable
    return-object
    :search-input.sync="search"
    placeholder="Write at least 3 characters to start searching"
    label="Product"
    :readonly="multiple ? false : selectedProduct != undefined"
    :multiple="multiple"
    :loading="loading"
    :rules="[validate()]"
  >
    <template v-slot:selection="{ item }">
      <slot name="selection" :item="item">
        <span>
          {{ item.storeProduct.productNumber ? item.storeProduct.productNumber + " - " : "" }}
          {{ item.storeProduct.name }}
          ({{ item.calculatedPriceAmount | toNumber(2, selectedProgram) }}
          {{ item.calculatedPriceAmountCurrency ? item.calculatedPriceAmountCurrency : "" }})</span
        >
      </slot>
    </template>
    <template v-slot:item="{ item }">
      <slot name="item" :item="item">
        <span>
          {{ item.storeProduct.productNumber ? item.storeProduct.productNumber + " - " : "" }}
          {{ item.storeProduct.name }}
          ({{ item.calculatedPriceAmount | toNumber(2, selectedProgram) }}
          {{ item.calculatedPriceAmountCurrency ? item.calculatedPriceAmountCurrency : "" }})</span
        >
      </slot>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../../services/api.service";

export default {
  name: "StoreProductsField",
  props: {
    value: [Array, Object],
    multiple: {
      type: Boolean,
      default: false
    },
    country: Object,
    awardVehicle: Object
  },
  data: () => ({
    products: [],
    search: "",
    selectedProduct: null,
    loading: false,
    storeProgram: null
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    fetchData() {
      this.loading = true;
      return this.fetchStoreProgram().then(() => {
        if (this.storeProgram) {
          return ApiService.post("/api/store/products/search", {
            keyword: this.search,
            isEffective: "Active",
            country: this.country
          })
            .then(({ data }) => {
              this.$set(this, "products", data.content);
              this.$forceUpdate();
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          return Promise.resolve();
        }
      });
    },
    fetchStoreProgram() {
      if (this.storeProgram) {
        return Promise.resolve(this.storeProgram);
      } else if (this.awardVehicle) {
        if (this.selectedStoreProgram && this.selectedStoreProgram.awardVehicle.id == this.awardVehicle.id) {
          this.storeProgram = this.selectedStoreProgram;
          return Promise.resolve(this.selectedStoreProgram);
        } else {
          return ApiService.get("/api/storePrograms/byAwardVehicle/" + this.awardVehicle.id).then(({ data }) => {
            this.storeProgram = data;
            return this.$storeProgram.storeSelectStoreProgram(this.storeProgram).then(() => {
              return this.storeProgram;
            });
          });
        }
      } else if (this.selectedStoreProgram) {
        this.storeProgram = this.selectedStoreProgram;
        return Promise.resolve(this.selectedStoreProgram);
      } else {
        return Promise.resolve(null);
      }
    },
    validate() {
      if (this.multiple) {
        if (this.selectedProduct && this.selectedProduct.length > 0) {
          return true;
        } else {
          return "At least one product is needed";
        }
      } else {
        if (this.selectedProduct) {
          return true;
        } else {
          return "At least one product is needed";
        }
      }
    }
  },
  watch: {
    search() {
      if (this.search && this.search.trim().length >= 3) {
        this.fetchData();
      }
    },
    value: {
      immediate: true,
      handler(v) {
        if (v) {
          if (this.product || this.products.length == 0) {
            this.products = [v];
          }
          this.selectedProduct = v;
        }
      }
    }
  },
  computed: {
    ...mapGetters(["selectedStoreProgram", "selectedProgram"])
  }
};
</script>
