<template>
  <v-data-table
    :headers="computedHeaders"
    :items="items.existing"
    :value="value.existing"
    @input="onInput"
    :sort-by="['effectiveDate']"
  >
    <template v-if="!hideTitle" v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Promotion Deadlines</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialogGenerateQuartersOrHalves" v-if="!disabled" max-width="800">
          <template v-slot:activator="{ on: onDialog, attrs: attrsDialog }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                <v-btn
                  fab
                  small
                  v-bind="{ ...attrsDialog, ...attrsTooltip }"
                  v-on="{ ...onDialog, ...onTooltip }"
                  :disabled="!generateButtonEnabled || !deadlinesStart || !deadlinesEnd"
                  class="mr-2"
                >
                  <v-icon>mdi-chart-pie</v-icon>
                </v-btn>
              </template>
              <span>Generate Quarters or Halves</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>Generate Quarters or Halves </v-card-title>
            <v-card-text>
              <v-form :value="valid" v-model="valid">
                <v-container>
                  <v-row>
                    <v-alert border="top" colored-border type="info" elevation="2">
                      By generating Quarters or Halves, you will wipe out any current deadline dates. Generation is
                      based on the time period of the promotion start and end date and uses the fiscal year on-file
                      (4/1)
                    </v-alert>
                  </v-row>
                  <v-row>
                    <v-select
                      :items="['Quarters', 'Halves']"
                      v-model="generationType"
                      label="Generation Type"
                      hint="Generate Quarters or Halves based on fiscal year"
                      :rules="rules.generationType"
                    >
                    </v-select>
                  </v-row>
                  <v-row>
                    <v-text-field
                      type="number"
                      v-model="submissionDeadlinesOffset"
                      label="Claim Deadline (Days After End Date)"
                      hint="For example, if the deadline needs to be 45 days after the End Date, choose 45 here"
                      min="0"
                      value="0"
                    >
                    </v-text-field>
                  </v-row>
                  <v-row>
                    <v-text-field
                      type="number"
                      v-model="dayOfMonthOverride"
                      label="Claim Deadline (Override day of month)"
                      hint="For example, if the deadline needs to land on the 15th of the month, choose 15 here, otherwise leave blank."
                      :rules="rules.dayOfMonthOverride"
                      min="1"
                    >
                    </v-text-field>
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2" @click="dialogGenerateQuartersOrHalves = false">Cancel</v-btn>
                    <v-btn class="primary" :disabled="!valid" @click="generate">Generate</v-btn>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog" v-if="!disabled" width="auto">
          <template v-slot:activator="{ on: onDialog, attrs: attrsDialog }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: onTooltip, attrs: attrsTooltip }">
                <v-btn fab small v-bind="{ ...attrsDialog, ...attrsTooltip }" v-on="{ ...onDialog, ...onTooltip }">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>New Promotion Deadline</span>
            </v-tooltip>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-form ref="form" :value="valid" @submit.prevent="onSave" v-model="valid">
                <v-container>
                  <v-row>
                    <v-col cols="4">
                      <DateTimePickerField
                        v-model="editedItem.effectiveDate"
                        v-if="dialog"
                        label="Effective Date"
                        defaultTime="00:00"
                        required
                      ></DateTimePickerField>
                    </v-col>
                    <v-col cols="4">
                      <DateTimePickerField
                        v-model="editedItem.expirationDate"
                        v-if="dialog"
                        label="Expiration Date"
                        defaultTime="23:59:59"
                        :rules="[validationRules.expirationDateValid]"
                        required
                      ></DateTimePickerField>
                    </v-col>
                    <v-col cols="4">
                      <DateTimePickerField
                        v-if="dialog"
                        v-model="editedItem.submissionDeadlineDate"
                        label="Submission Deadline Date"
                        defaultTime="23:59:59"
                        :rules="[validationRules.submissionDeadlineValid]"
                        required
                      ></DateTimePickerField>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="onSave" :disabled="!valid">{{ $i18n.translate("Save") }}</v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.effectiveDate="{ item }">
      <span v-if="item.effectiveDate"> {{ item.effectiveDate | formatDateClient("MM/DD/YYYY", selectedClient) }}</span>
    </template>
    <template v-slot:item.expirationDate="{ item }">
      <span v-if="item.expirationDate">
        {{ item.expirationDate | formatDateClient("MM/DD/YYYY", selectedClient) }}</span
      >
    </template>
    <template v-slot:item.submissionDeadlineDate="{ item }">
      <span v-if="item.submissionDeadlineDate">
        {{ item.submissionDeadlineDate | formatDateClient("MM/DD/YYYY", selectedClient) }}</span
      >
    </template>
    <template v-if="!hideActions" v-slot:item.actions="{ item }">
      <v-icon class="mr-2" v-if="!disabled" @click="onEditItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon v-if="!disabled" @click="onDeleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:footer>
      <v-alert dense type="error" v-if="errorMessage">
        {{ errorMessage }}
      </v-alert>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
import DateTimePickerField from "../fields/DateTimePickerField";

export default {
  name: "PromotionDeadlinesTable",
  components: {
    DateTimePickerField
  },
  props: {
    promotionId: Number,
    value: Object,
    disabled: Boolean,
    hideTitle: Boolean,
    hideActions: Boolean,
    generateButtonEnabled: {
      type: Boolean,
      default: false
    },
    deadlinesStart: String,
    deadlinesEnd: String
  },
  data: () => ({
    valid: false,
    loaded: false,
    dialog: false,
    dialogGenerateQuartersOrHalves: false,
    generationType: "Quarters",
    submissionDeadlinesOffset: 0,
    dayOfMonthOverride: null,
    headers: [
      { text: "Effective Date", value: "effectiveDate", align: "center" },
      { text: "Expiration Date", value: "expirationDate", align: "center" },
      {
        text: "Submission Deadline Date",
        value: "submissionDeadlineDate",
        align: "center"
      },
      { text: "Actions", value: "actions", align: "center", sortable: false }
    ],
    items: {
      existing: [],
      deleted: []
    },
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    errorMessage: null,
    rules: {
      generationType: [v => !!v || "Required"],
      dayOfMonthOverride: [v => v == null || v === "" || (v > 0 && v <= 31) || "The value must be between 1 to 31"]
    }
  }),

  computed: {
    ...mapGetters(["selectedClient", "selectedProgram"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Promotion Deadline" : "Edit Promotion Deadline";
    },
    computedHeaders() {
      return this.headers.reduce((acc, cur) => {
        if (this.hideActions && cur.value && cur.value == "actions") {
          return acc;
        }
        acc.push(cur);
        return acc;
      }, []);
    },
    validationRules() {
      return {
        expirationDateValid: value => {
          if (!value) return true;
          const effectiveDate =
            this.editedItem && this.editedItem?.effectiveDate ? new Date(this.editedItem.effectiveDate).getTime() : 0;
          const expirationDate =
            this.editedItem && this.editedItem.expirationDate ? new Date(this.editedItem?.expirationDate).getTime() : 0;
          if (expirationDate < effectiveDate) {
            return "Expiration Date must be on or after Effective Date";
          } else {
            return true;
          }
        },
        submissionDeadlineValid: value => {
          if (!value) return true;
          const effectiveDate =
            this.editedItem && this.editedItem?.effectiveDate ? new Date(this.editedItem.effectiveDate).getTime() : 0;
          const expirationDate =
            this.editedItem && this.editedItem.expirationDate ? new Date(this.editedItem?.expirationDate).getTime() : 0;
          const submissionDeadline =
            this.editedItem && this.editedItem.submissionDeadlineDate
              ? new Date(this.editedItem?.submissionDeadlineDate).getTime()
              : 0;
          if (submissionDeadline < effectiveDate) {
            return "Submission Deadline Date must be on or after Effective Date";
          } else if (submissionDeadline < expirationDate) {
            return "Submission Deadline Date must be on or after Expiration Date";
          } else {
            return true;
          }
        }
      };
    }
  },

  watch: {
    dialog(val) {
      val || this.onClose();
    },
    "editedItem.expirationDate": {
      handler() {
        // Wait for $refs to be available after DOM updates
        this.$nextTick(() => {
          if (this.$refs.form) {
            this.$refs.form.validate();
          }
        });
      },
      deep: true
    },
    "editedItem.submissionDeadlineDate": {
      handler() {
        // Wait for $refs to be available after DOM updates
        this.$nextTick(() => {
          if (this.$refs.form) {
            this.$refs.form.validate();
          }
        });
      },
      deep: true
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.items = this.value;
      this.loaded = true;
    },

    onInput() {
      this.$emit("input", this.items);
    },

    onEditItem(item) {
      this.editedIndex = this.items.existing.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    onDeleteItem(item) {
      const index = this.items.existing.indexOf(item);
      const deletedItems = this.items.existing.splice(index, 1);
      this.items.deleted = this.items.deleted.concat(deletedItems);
      this.$emit("input", this.items);
      if (this.loaded) {
        this.$emit("change");
      }
    },

    onClose() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    },

    onSave() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.editedItem.dirty = true;
      if (this.editedIndex > -1) {
        Object.assign(this.items.existing[this.editedIndex], this.editedItem);
      } else {
        this.items.existing.push(this.editedItem);
      }
      this.$emit("input", this.items);
      if (this.loaded) {
        this.$emit("change");
      }
      this.onClose();
    },

    generate() {
      this.items.deleted = this.items.existing;
      this.items.existing = [];

      let deadlines = this.$client.generateDeadlines(
        this.selectedClient,
        this.deadlinesStart,
        this.deadlinesEnd,
        this.generationType,
        this.submissionDeadlinesOffset,
        this.dayOfMonthOverride
      );
      this.items.existing = deadlines;
      this.dialogGenerateQuartersOrHalves = false;
    }
  },
  mounted() {}
};
</script>
