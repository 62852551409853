<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card v-for="item in visibleItems" :key="item.id" class="mt-2 pl-2">
          <v-card-title class="mb-n10 ">
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-icon @click="onDeleteItem(item)">mdi-delete</v-icon>
            </v-card-actions>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="3" class="ma-1">
                <DateTimePickerField
                  v-model="item.effectiveDate"
                  label="Effective Date"
                  hint="Optional.  Will default to promotion start date."
                  defaultTime="00:00"
                ></DateTimePickerField>
                <DateTimePickerField
                  v-model="item.expirationDate"
                  label="Expiration Date"
                  hint="Optional.  Will default to promotion end date."
                  defaultTime="23:59"
                ></DateTimePickerField>
                <AwardVehicleField
                  v-model="item.ruleActions[0].context.awardVehicle"
                  :valueId="item.ruleActions[0].context.awardVehicleId"
                  label="Award Vehicle"
                >
                </AwardVehicleField>
              </v-col>
              <v-col cols="8" class="ma-1">
                <v-text-field
                  v-model="item.ruleCondition.spelExpression"
                  label="Condition"
                  :rules="[v => !!v || 'Condition is required']"
                ></v-text-field>
                <v-text-field
                  v-model="item.ruleActions[0].context.expression"
                  label="Expression"
                  :rules="[v => !!v || 'Expression is required']"
                ></v-text-field>
                <v-text-field v-model="item.errorMessage" label="Error Message"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DateTimePickerField from "../fields/DateTimePickerField.vue";
import AwardVehicleField from "../fields/AwardVehicleField";

export default {
  name: "RuleTable",
  props: {
    value: Array
  },
  components: {
    AwardVehicleField,
    DateTimePickerField
  },
  data: () => ({
    items: [],

    ruleId: undefined
  }),
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(v) {
        this.items = v;
      }
    }
  },
  computed: {
    visibleItems() {
      if (this.items) {
        return this.items.filter(i => {
          return !i.toBeDeleted;
        });
      } else {
        return [];
      }
    }
  },

  created() {
    this.loadRules();
  },

  methods: {
    loadRules() {
      let rules = this.items;
      if (rules) {
        rules.forEach(rule => {
          if (rule.ruleActions && rule.ruleActions.length > 0) {
            rule.ruleActions.forEach(ruleAction => {
              if (ruleAction.stringContext) {
                try {
                  ruleAction.context = JSON.parse(ruleAction.stringContext);
                } catch (error) {
                  ruleAction.context = {};
                }
              } else {
                ruleAction.context = {};
              }
            });
          } else {
            rule.ruleActions = [
              {
                context: {}
              }
            ];
          }
        });
        this.items = rules;
      }
    },
    onDeleteItem(item) {
      this.$emit("deleteProductPayout", item);
    }
  }
};
</script>
