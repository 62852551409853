var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items.existing,"value":_vm.value.existing},on:{"input":_vm.onInput},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.label))]),_c('v-spacer'),(!_vm.disabled)?_c('v-dialog',{attrs:{"persistent":"","width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onDialog = ref.on;
var attrsDialog = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
var attrsTooltip = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"fab":"","small":""}},'v-btn',Object.assign({}, attrsDialog, attrsTooltip),false),Object.assign({}, onDialog, onTooltip)),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("New"))+" "+_vm._s(_vm.$i18n.translate("Promotion"))+" "+_vm._s(_vm.$i18n.translate("Participant")))])])]}}],null,false,3643869801),model:{value:(_vm.participantsToAddDialog),callback:function ($$v) {_vm.participantsToAddDialog=$$v},expression:"participantsToAddDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Add Participants")]),_c('v-spacer'),_c('v-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onParticipantsToAddFilter.apply(null, arguments)}}},[_c('v-text-field',{staticClass:"mr-4",attrs:{"label":_vm.$i18n.translate('Filter') + ' ' + _vm.$i18n.translate('Participants'),"flat":"","solo-inverted":"","hide-details":"","clearable":"","clear-icon":"mdi-close-circle-outline"},on:{"click:clear":_vm.onParticipantsToAddClearFilter},model:{value:(_vm.participantsToAdd.search),callback:function ($$v) {_vm.$set(_vm.participantsToAdd, "search", $$v)},expression:"participantsToAdd.search"}})],1),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Advanced Search")))])])]}}],null,false,4278830429)},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-subheader',[_vm._v(_vm._s(_vm.$i18n.translate("Advanced Search")))]),_c('v-list-item',{attrs:{"selectable":""}},[_c('v-list-item-content',[_c('ParticipantTypesField',{attrs:{"dense":"","label":"Participant Types"},on:{"input":_vm.getParticipantsToAddData},model:{value:(_vm.participantsToAdd.filterByParticipantTypes),callback:function ($$v) {_vm.$set(_vm.participantsToAdd, "filterByParticipantTypes", $$v)},expression:"participantsToAdd.filterByParticipantTypes"}})],1)],1)],1)],1)],1),(_vm.hasFilters)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.onParticipantsToAddClearSearch}},Object.assign({}, onTooltip)),[_c('v-icon',[_vm._v("mdi-magnify-close")])],1)]}}],null,false,3842542905)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Clear Search")))])]):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"show-select":"","items":_vm.participantsToAdd.items,"headers":_vm.participantsToAdd.headers,"server-items-length":_vm.participantsToAdd.total,"options":_vm.participantsToAddOptions,"loading":_vm.participantsToAdd.loading},on:{"update:options":function($event){_vm.participantsToAddOptions=$event}},scopedSlots:_vm._u([{key:"item.organization",fn:function(ref){
var item = ref.item;
return [(item.organization)?_c('span',[_vm._v(" "+_vm._s(item.organization.name)+" ")]):_vm._e()]}},{key:"item.updatedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateFromNow")(item.updatedDate))+" ")]}}],null,false,3389593578),model:{value:(_vm.participantsToAdd.selected),callback:function ($$v) {_vm.$set(_vm.participantsToAdd, "selected", $$v)},expression:"participantsToAdd.selected"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.onParticipantsToAddClose}},[_vm._v(_vm._s(_vm.$i18n.translate("Cancel")))]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onParticipantsToAddIncludeSubmit}},[_vm._v("Include")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.onParticipantsToAddExcludeSubmit}},[_vm._v("Exclude")])],1)],1)],1):_vm._e(),(!_vm.disabled)?_c('v-dialog',{attrs:{"persistent":"","width":"500"},model:{value:(_vm.participantEditDialog),callback:function ($$v) {_vm.participantEditDialog=$$v},expression:"participantEditDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Edit "+_vm._s(_vm.$i18n.translate("Participant"))+" - "+_vm._s(_vm.editedItem && _vm.editedItem.participant ? _vm.editedItem.participant.fullName : ""))])]),_c('v-card-text',[_c('v-form',{attrs:{"value":_vm.valid},on:{"submit":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{attrs:{"label":"Include in Promotion?"},model:{value:(_vm.editedItem.includeInPromotion),callback:function ($$v) {_vm.$set(_vm.editedItem, "includeInPromotion", $$v)},expression:"editedItem.includeInPromotion"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":_vm.onClose}},[_vm._v(_vm._s(_vm.$i18n.translate("Cancel")))]),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.valid},on:{"click":_vm.onSave}},[_vm._v(_vm._s(_vm.$i18n.translate("Save")))])],1)],1)],1):_vm._e()],1)]},proxy:true},{key:"item.includeInPromotion",fn:function(ref){
var item = ref.item;
return [(item.includeInPromotion)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])]}},{key:"item.participant.organization",fn:function(ref){
var item = ref.item;
return [(item.participant.organization)?_c('span',[_vm._v(" "+_vm._s(item.participant.organization.name)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.disabled)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.onEditItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(!_vm.disabled)?_c('v-icon',{on:{"click":function($event){return _vm.onDeleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }