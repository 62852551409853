var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":""},on:{"click":function($event){_vm.isEditing = true}}},on),[(_vm.isEditing)?_c('v-icon',[_vm._v("mdi-close")]):_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[(_vm.isEditing)?_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Close")))]):_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Edit"))+" "+_vm._s(_vm.$i18n.translate("Claim"))+" "+_vm._s(_vm.$i18n.translate("Product")))])]),(_vm.isEditing)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.addNewRule()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,2646362986)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Add"))+" "+_vm._s(_vm.$i18n.translate("Claim"))+" "+_vm._s(_vm.$i18n.translate("Product")))])]):_vm._e(),(_vm.isEditing)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.saveRuleGroup()}}},on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}],null,false,3736858326)},[_c('span',[_vm._v(_vm._s(_vm.$i18n.translate("Save")))])]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.visibleItems},scopedSlots:_vm._u([{key:"item.ruleCondition",fn:function(ref){
var item = ref.item;
return [(item && item.ruleCondition)?_c('v-text-field',{attrs:{"disabled":!_vm.isEditing,"label":"Condition"},model:{value:(item.ruleCondition.spelExpression),callback:function ($$v) {_vm.$set(item.ruleCondition, "spelExpression", $$v)},expression:"item.ruleCondition.spelExpression"}}):_vm._e()]}},{key:"item.expression",fn:function(ref){
var item = ref.item;
return [(item && item.ruleAction && item.ruleAction[0] && item.ruleAction[0].context)?_c('v-text-field',{attrs:{"disabled":!_vm.isEditing,"label":"Expression"},model:{value:(item.ruleAction[0].context.expression),callback:function ($$v) {_vm.$set(item.ruleAction[0].context, "expression", $$v)},expression:"item.ruleAction[0].context.expression"}}):_vm._e()]}},{key:"item.awardVehicle",fn:function(ref){
var item = ref.item;
return [(item && item.ruleAction && item.ruleAction[0] && item.ruleAction[0].context)?_c('AwardVehicleField',{attrs:{"disabled":!_vm.isEditing,"valueId":item.ruleAction[0].context.awardVehicleId},model:{value:(item.ruleAction[0].context.awardVehicle),callback:function ($$v) {_vm.$set(item.ruleAction[0].context, "awardVehicle", $$v)},expression:"item.ruleAction[0].context.awardVehicle"}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isEditing)?_c('v-icon',{on:{"click":function($event){return _vm.$set(item, 'toBeDeleted', true)}}},[_vm._v("mdi-delete")]):_vm._e()]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }