<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="participantStatusTypes"
    item-value="name"
    :item-text="item => `${item.name} - ${item.description}`"
    clearable
    return-object
  >
    <template v-slot:selection="data"> {{ data.item.name }} - {{ data.item.description }} </template>
  </v-select>
</template>

<script>
import ApiService from "../../services/api.service";

export default {
  name: "ParticipantStatusField",
  props: {
    value: Object
  },
  data: () => ({
    participantStatusTypes: []
  }),
  methods: {
    onInput(obj) {
      this.$emit("input", obj);
    },
    fetchData() {
      ApiService.get("/api/types/participantStatuses").then(({ data }) => {
        this.participantStatusTypes = data;
      });
    }
  },
  mounted() {
    this.fetchData();
  }
};
</script>
